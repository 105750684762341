import { useEffect, useRef, useState } from 'react'
import LogsApi from '@utils/logs-api'

interface SendTrackingEventProps {
  authenticationId: string
  processor: string
  stepDescriptor?: string
  eventName: string
  data?: Record<string, any>
}

const sendTrackingEvent = ({
  authenticationId,
  processor,
  stepDescriptor,
  eventName,
  data = {}
}: SendTrackingEventProps) => {
  const eventString = stepDescriptor
    ? `${authenticationId} -> ${processor} -> ${stepDescriptor} -> ${eventName}`
    : `${authenticationId} -> ${processor} -> ${eventName}`

  const payload = {
    processor,
    event: eventString,
    timestamp: Date.now(),
    ...data
  }

  try {
    LogsApi.logTrackingEvent(payload)
  } catch (error) {
    const errorEventString = `${authenticationId} -> ${processor} -> Failed to send tracking event`
    LogsApi.logError({
      event: errorEventString,
      originalPayload: payload,
      error: error instanceof Error ? error.message : String(error),
      stackTrace: error instanceof Error ? error.stack : undefined
    })
  }
}

type Props = {
  authenticationId: string
  processor: string
  stepDescriptor?: string
  src?: string
  validOrigins: string[]
  onValidOriginEventCallback: (result: any) => Promise<void>
}

const useIframeTracking = ({
  authenticationId,
  processor,
  stepDescriptor,
  src,
  validOrigins,
  onValidOriginEventCallback
}: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const loadTimestampRef = useRef(Date.now())
  const isInitializedRef = useRef(false)

  const handleSendTrackingEvent = (
    eventName: string,
    data: Record<string, any>
  ) => {
    sendTrackingEvent({
      authenticationId,
      processor,
      stepDescriptor,
      eventName,
      data
    })
  }

  const handleVisibilityChange = () => {
    if (!isLoaded) return

    const eventData = {
      url: src,
      ...(document.visibilityState === 'hidden' && {
        timeActive: Date.now() - loadTimestampRef.current
      })
    }

    handleSendTrackingEvent('iframe_visibility_changed', {
      ...eventData,
      newState: document.visibilityState
    })
  }

  const handleMessage = (event: MessageEvent) => {
    if (!validOrigins.includes(event.origin)) return

    try {
      const content = JSON.parse(event.data as string)
      handleSendTrackingEvent('iframe_post_message_received', { content })
      onValidOriginEventCallback(content)
    } catch (error) {
      handleSendTrackingEvent('iframe_post_message_error', {
        error: String(error),
        originalMessage: event.data
      })
    }
  }

  const handleNetworkChange = () => {
    if (isLoaded) {
      handleSendTrackingEvent('iframe_network_change', {
        url: src,
        isOnline: navigator.onLine,
        timeActive: Date.now() - loadTimestampRef.current
      })
    }
  }

  useEffect(() => {
    const handleIframeLoad = () => {
      setIsLoaded(true)
      handleSendTrackingEvent('iframe_loaded', { url: src })
    }

    const handlePageHide = () => {
      if (!isLoaded) return
      handleSendTrackingEvent('page_hide', {
        url: src,
        timeActive: Date.now() - loadTimestampRef.current
      })
    }

    // Init tracking
    if (!isInitializedRef.current) {
      handleSendTrackingEvent('iframe_initialized', { url: src })
      isInitializedRef.current = true
    }

    // Event listeners
    window.addEventListener('message', handleMessage, false)
    window.addEventListener('online', () => handleNetworkChange())
    window.addEventListener('offline', () => handleNetworkChange())
    window.addEventListener('pagehide', handlePageHide, false)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    if (iframeRef.current)
      iframeRef.current.addEventListener('load', handleIframeLoad)

    return () => {
      window.removeEventListener('message', handleMessage)
      window.removeEventListener('online', () => handleNetworkChange())
      window.removeEventListener('offline', () => handleNetworkChange())
      window.removeEventListener('pagehide', handlePageHide)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      iframeRef.current?.removeEventListener('load', handleIframeLoad)

      if (isLoaded) {
        handleSendTrackingEvent('iframe_closed', {
          url: src,
          timeActive: Date.now() - loadTimestampRef.current
        })
      }
    }
  }, [authenticationId, processor, src, isLoaded])

  return { iframeRef, isLoaded, handleSendTrackingEvent }
}

export default useIframeTracking
