import { datadogLogs } from '@datadog/browser-logs'

export interface TrackingMetadata {
  network: string
  isOnline: boolean
  memoryUsage: number | undefined
  timeZone: string
}

export interface EnviromentInfo {
  isWebView: boolean
  platform: string
  screenSize: {
    width: number
    height: number
  }
  orientation: OrientationType
}

export interface TrackingPayload {
  processor: string
  event: string
  timestamp: number
  [key: string]: any
}

export interface ErrorPayload {
  event: string
  originalPayload: TrackingPayload
  error: string
  stackTrace: string | undefined
}

class LogsApi {
  static logTrackingEvent = (payload: TrackingPayload) => {
    const metadata = LogsApi.getTrackingMetadata()
    const environment = LogsApi.getEnvironmentInfo()

    datadogLogs.logger.info(payload.event, {
      ...payload,
      environment,
      metadata
    })
  }

  static logError = (errorPayload: ErrorPayload) => {
    datadogLogs.logger.error(errorPayload.event, { ...errorPayload })
  }

  static getTrackingMetadata = (): TrackingMetadata => ({
    network: (navigator as any).connection?.type || 'unknown',
    isOnline: navigator.onLine,
    memoryUsage: (performance as any)?.memory?.usedJSHeapSize,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  static getEnvironmentInfo = (): EnviromentInfo => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isWebView =
      /(webview|wv)/.test(userAgent) ||
      /android.*wv/.test(userAgent) ||
      /iphone|ipod|ipad.*applewebkit(?!.*safari)/i.test(userAgent)

    const isIOS = /iphone|ipod|ipad/i.test(userAgent)
    const isAndroid = /android/i.test(userAgent)

    return {
      isWebView,
      platform: isIOS ? 'ios' : isAndroid ? 'android' : 'web',
      screenSize: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      orientation: window.screen.orientation?.type || 'unknown'
    }
  }
}

export default LogsApi
