import { AuthenticationProvider, AuthenticationStatus } from '@enums'

export const getAuthenticationStatusString = (
  status?: AuthenticationStatus
): string => {
  if (status !== undefined && status !== null)
    return getKeyByValue(AuthenticationStatus, status) ?? ''
  return getKeyByValue(AuthenticationStatus, AuthenticationStatus.FAILED) ?? ''
}

export const getKeyByValue = (object: any, value: any) =>
  Object.keys(object).find((key) => object[key] === value)

export const getAuthProviderKey = (value: number): string => {
  const keys = Object.keys(AuthenticationProvider)
  const stringKeys = keys.filter((key) => isNaN(Number(key)))

  if (value < 0 || value >= stringKeys.length) {
    throw new Error(`Invalid AuthenticationProvider value: ${value}`)
  }

  return stringKeys[value]
}
